<template>
  <v-card height="100%" tile class="pa-10">
    <v-card-text class="pa-1">
      <v-container class="pa-2" fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card>
              <v-card-text class="pa-0">
                <table-membership></table-membership>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import TableMembership from "@/components/tables/TableMembership.vue";
export default {
  name: "memberships-index",
  components: { TableMembership },
  data: () => ({}),
};
</script>

<style>
</style>